@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}


.HeaderCartButton_button__1mdHx {
  cursor: pointer;
  font: inherit;
  border: none;
  background-color: #ffffff;
  color: #000000;
  padding: 0.75rem 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 25px;
  font-weight: bold;
}

.HeaderCartButton_button__1mdHx:hover,
.HeaderCartButton_button__1mdHx:active {
  background-color: #CCCCCC;
}

.HeaderCartButton_icon__1PbAq {
  width: 1.35rem;
  height: 1.35rem;
  margin-right: 0.5rem;
}

.HeaderCartButton_badge__16qJv {
  background-color: #e7ad10;
  padding: 0.25rem 1rem;
  border-radius: 25px;
  margin-left: 1rem;
  font-weight: bold;
}

/* .button:hover .badge,
.button:active .badge {
  background-color: #92320c;
} */

.HeaderCartButton_bump__13tks {
  -webkit-animation: HeaderCartButton_bump__13tks 300ms ease-out;
          animation: HeaderCartButton_bump__13tks 300ms ease-out;
}

@-webkit-keyframes HeaderCartButton_bump__13tks {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  30% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes HeaderCartButton_bump__13tks {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  30% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media (max-width: 768px) {
  .HeaderCartButton_button__1mdHx {
    padding: 0.45rem;
    border-radius: 5px;
  }

  .HeaderCartButton_icon__1PbAq {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
  }
  
  .HeaderCartButton_badge__16qJv {
    background-color: #e7ad10;
    padding: 0.1rem 0.8rem;
    border-radius: 10px;
    margin-left: 1rem;
    font-weight: bold;
  }
}


.Header_header__3Vd8V {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: #e7ad10;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.Header_main-image__1wOpG {
  width: 100%;
  height: 27rem;
  z-index: 0;
  overflow: hidden;
}

.Header_main-image__1wOpG img {
  width: 110%;
  height: 100%;
  object-fit: cover;
  -webkit-transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
          transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
}

@media (max-width: 768px) {
  .Header_header__3Vd8V h1 {
    font-size: 1.2rem;
  }
}

.MealsSummary_summary__phqC9 {
  text-align: center;
  max-width: 45rem;
  width: 90%;
  margin: auto;
  margin-top: -10rem;
  position: relative;
  background-color: #383838;
  color: white;
  border-radius: 14px;
  padding: 1rem;
  box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.25);
}

.MealsSummary_summary__phqC9 h2 {
  font-size: 2rem;
  margin-top: 0;
}

.MealsSummary_nav__2VeHE ul {
  display: inline-flex;
  grid-gap: 35px;
  gap: 35px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.MealsSummary_nav__2VeHE li {
  display: block;
  font-size: 1.25rem;
}

.MealsSummary_nav__2VeHE a {
  text-decoration: none;
  color: #e7ad10;
}

.MealsSummary_nav__2VeHE a:hover,
.MealsSummary_nav__2VeHE a:active,
.MealsSummary_nav__2VeHE a.MealsSummary_active__UYoV4 {
  color: #ffffff;
}

.MealsSummary_nav__2VeHE a img {
  height: 70px;
}
.Card_card__2H7lb {
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  background-color: white;
}

.Input_input__1NBcL {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.Input_input__1NBcL label {
  font-weight: bold;
  margin-right: 1rem;
}

.Input_input__1NBcL input {
  width: 3rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font: inherit;
  padding-left: 0.5rem;
}

.MealItemForm_form__cplFK {
  text-align: right;
}

.MealItemForm_form__cplFK button {
  font: inherit;
  cursor: pointer;
  background-color: #ebbd3f;
  border: 1px solid #ebbd3f;
  color: white;
  padding: 0.25rem 2rem;
  border-radius: 20px;
  font-weight: bold;
}

.MealItemForm_form__cplFK button:hover,
.MealItemForm_form__cplFK button:active {
  background-color: #e7ad10;
  border-color: #e7ad10;
}
.MealItem_meal__3Ei9K {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}

.MealItem_meal__3Ei9K h3 {
  margin: 0 0 0.25rem 0;
}

.MealItem_description__2WXQs {
  font-style: italic;
}

.MealItem_price__3JN_D {
  margin-top: 0.25rem;
  font-weight: bold;
  color: #ebbd3f;
  font-size: 1.25rem;
}
.AvailableMeals_meals__3v1Ea {
  max-width: 60rem;
  width: 90%;
  margin: 2rem auto;
  -webkit-animation: AvailableMeals_meals-appear__1ZBXu 1s ease-out forwards;
          animation: AvailableMeals_meals-appear__1ZBXu 1s ease-out forwards;
}

.AvailableMeals_MealsLoading__1T4Iw {
  text-align: center;
  color: white;
}

.AvailableMeals_MealsError__2dfIO {
  text-align: center;
  color: red;
}

.AvailableMeals_meals__3v1Ea ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@-webkit-keyframes AvailableMeals_meals-appear__1ZBXu {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes AvailableMeals_meals-appear__1ZBXu {
  from {
    opacity: 0;
    -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.Modal_backdrop__YCHJf {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}

.Modal_modal__1lnBd {
  position: fixed;
  top: 15vh;
  left: 5%;
  width: 90%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  -webkit-animation: Modal_slide-down__2rqch 300ms ease-out forwards;
          animation: Modal_slide-down__2rqch 300ms ease-out forwards;
}

@media (min-width: 768px) {
  .Modal_modal__1lnBd {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@-webkit-keyframes Modal_slide-down__2rqch {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes Modal_slide-down__2rqch {
  from {
    opacity: 0;
    -webkit-transform: translateY(-3rem);
            transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.CartItem_cart-item__1hcE7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e7ad10;
  padding: 1rem 0;
  margin: 1rem 0;
}

.CartItem_cart-item__1hcE7 h2 {
  margin: 0 0 0.5rem 0;
  color: #363636;
}

.CartItem_summary__zT-S4 {
  width: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CartItem_price__3fB9Q {
  font-weight: bold;
  color: #ebbd3f;
}

.CartItem_amount__3-ePz {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  color: #363636;
}

.CartItem_actions__1nZiy {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .CartItem_actions__1nZiy {
    flex-direction: row;
  }
}

.CartItem_cart-item__1hcE7 button {
  font: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  color: #e7ad10;
  border: 1px solid #e7ad10;
  width: 3rem;
  text-align: center;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
  margin: 0.25rem;
}

.CartItem_cart-item__1hcE7 button:hover,
.CartItem_cart-item__1hcE7 button:active {
  background-color: #e7ad10;
  color: white;
}
.Cart_cart-items__3ia5k {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 20rem;
  overflow: auto;
}

.Cart_total__1vSPW {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.Cart_actions__31n-H {
  text-align: right;
}

.Cart_actions__31n-H button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #ebbd3f;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin-left: 1rem;
}

.Cart_actions__31n-H button:hover,
.Cart_actions__31n-H button:active {
  background-color: #e7ad10;
  border-color: #e7ad10;
  color: white;
}

.Cart_actions__31n-H .Cart_button--alt__3kdWb {
  color: #ebbd3f;
}

.Cart_actions__31n-H .Cart_button__2MDh- {
  background-color: #ebbd3f;
  color: white;
}
.Cart_centered__lp55d {
  display: block;
  width: 80px;
  margin: 0 auto;
}

.Checkout_form__ECUDj {
  margin: 1rem 0;
  height: 19rem;
  overflow: auto;
}

.Checkout_control__hgt_w {
  margin-bottom: 0.5rem;
}

.Checkout_control__hgt_w label {
  font-weight: bold;
  margin-bottom: 0.25rem;
  display: block;
}

.Checkout_control__hgt_w input {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 20rem;
  max-width: 100%;
}

.Checkout_actions__34vzd {
  display: flex;
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
}

.Checkout_actions__34vzd button {
  font: inherit;
  color: #363636;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 25px;
  padding: 0.5rem 2rem; 
}

.Checkout_actions__34vzd button:hover,
.Checkout_actions__34vzd button:active {
  background-color: #f7e6b7;
}

.Checkout_actions__34vzd .Checkout_submit__-PWFm {
  border: 1px solid #ebbd3f;
  background-color: #ebbd3f;
  color: white;
}

.Checkout_actions__34vzd .Checkout_submit__-PWFm:hover,
.Checkout_actions__34vzd .Checkout_submit__-PWFm:active {
  background-color: #e7ad10;
}

.Checkout_invalid__2caWg label {
  color: #ca3e51;
}

.Checkout_invalid__2caWg input {
  border-color: #aa0b20;
  background-color: #ffeff1;
}
.LoadingSpinner_spinner__3ovYC {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.LoadingSpinner_spinner__3ovYC:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #e7ad10;
  border-color: #e7ad10 transparent #e7ad10 transparent;
  -webkit-animation: LoadingSpinner_spinner__3ovYC 1.2s linear infinite;
          animation: LoadingSpinner_spinner__3ovYC 1.2s linear infinite;
}
@-webkit-keyframes LoadingSpinner_spinner__3ovYC {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes LoadingSpinner_spinner__3ovYC {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Notification_notification__1Pd_H {
    width: 100%;
    height: 3rem;
    background-color: #1a8ed1;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 10%;
    align-items: center;
    color: white;
  }
  
  .Notification_notification__1Pd_H h2,
  .Notification_notification__1Pd_H p {
    font-size: 1rem;
    margin: 0;
  }
  
  .Notification_error__1lohP {
    background-color: #690000;
  }
  
  .Notification_success__2gV4n {
    background-color: #1ad1b9;
  }
